var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "panel panel-bordered mb-0" }, [
        _vm._m(0),
        _c("div", { staticClass: "panel-body" }, [
          _c("form", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(1),
              _c("div", { staticClass: "col-md-12" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.barcode.barcode,
                      expression: "barcode.barcode",
                    },
                  ],
                  ref: "barcode_input",
                  staticClass: "form-control form__input",
                  attrs: {
                    type: "text",
                    required: "",
                    maxlength: "100",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.barcode.barcode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.barcode, "barcode", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(2),
              _c("div", { staticClass: "col-md-12" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.barcode.note,
                      expression: "barcode.note",
                    },
                  ],
                  staticClass: "form-control form__input",
                  attrs: {
                    type: "text",
                    maxlength: "200",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.barcode.note },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.barcode, "note", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(3),
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.barcode.status,
                        expression: "barcode.status",
                      },
                    ],
                    staticClass: "form-control w-200",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.barcode,
                          "status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "active" } }, [
                      _vm._v("ACTIVE"),
                    ]),
                    _c("option", { attrs: { value: "inactive" } }, [
                      _vm._v("INACTIVE"),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "panel-footer" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-default mx-10",
                attrs: { type: "button" },
                on: { click: _vm.close },
              },
              [
                _c("i", {
                  staticClass: "icon md-close",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Cancel "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.addBarcode },
              },
              [
                _c("i", {
                  staticClass: "icon md-check",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Create "),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [
        _vm._v("Create Illicit Barcode"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "col-md-0 form-control-label form__label" },
      [_c("h5", [_vm._v("Barcode")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "col-md-0 form-control-label form__label" },
      [_c("h5", [_vm._v("Note")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "col-md-0 form-control-label form__label" },
      [_c("h5", [_vm._v("Status")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }