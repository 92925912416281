<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Illicit List</h1>
      <breadcrumb name="ILLICIT_BARCODE_LIST"></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
          @click="reloadTable"
        >
          <i class="icon md-refresh-alt" aria-hidden="true"></i> Reload table
        </button>

        <button
          type="button"
          class="btn btn-round btn-dark btn-outline mx-10"
          @click="hideInactive = !hideInactive"
        >
          {{ hideInactive ? "Show all" : "Hide Inactive" }}
        </button>

        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="openCreateModal"
        >
          <i class="icon md-plus" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              :rows="list"
              :columns="columns"
              @on-row-click="onRowClick"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
            >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'status'">
                  {{ props.row.status | toUpperCase }}
                </div>
                <div v-else-if="props.column.field == 'end_date'">
                  {{ props.row.end_date == null ? "n/a" : props.row.end_date }}
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal name="create-modal" :width="500" height="auto">
      <CreateModal @submit="fetchBarcodes" />
    </modal>
    <modal name="update-modal" :width="500" height="auto">
      <UpdateModal
        @submit="fetchBarcodes"
        :barcode="selectedRow"
        @closed="onUpdateModalClosed"
      />
    </modal>
  </div>
</template>

<script>
import { illicitBarcodeService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import { columns } from "@/views/illicit-barcode/list/model";
import CreateModal from "@/views/illicit-barcode/list/CreateModal.vue";
import UpdateModal from "@/views/illicit-barcode/list/UpdateModal.vue";

export default {
  name: "BarcodeList",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      list: [],
      codes: [],
      hideInactive: true,
      columns: columns,
      selectedRow: null,
    };
  },

  components: { CreateModal, UpdateModal },

  watch: {
    hideInactive() {
      this.filter();
    },

    codes(v) {
      this.list = v;
    },
  },

  methods: {
    async onRowClick({ row }) {
      if (!row.id) return;

      const barcode = await illicitBarcodeService.getBarcodeById(row.id);

      this.selectedRow = barcode;
      this.$modal.show(`update-modal`);
    },

    async fetchBarcodes(show_all) {
      this.codes = await illicitBarcodeService.getCodes({
        show_all: show_all,
      });
    },

    filter() {
      this.hideInactive ? this.fetchBarcodes(0) : this.fetchBarcodes(1);
    },

    async reloadTable() {
      await this.fetchBarcodes();
      this.$notify("Updated");
    },

    async openCreateModal() {
      this.$modal.show(`create-modal`);
    },

    onUpdateModalClosed() {
      this.selectedRow = null;
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  created() {
    this.fetchBarcodes(0);
  },
};
</script>

<style lang="scss">
table.vgt-table tr {
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
}
</style>
