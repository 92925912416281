<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Create Illicit Barcode</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div class="form-group row">
              <label class="col-md-0 form-control-label form__label">
                <h5>Barcode</h5>
              </label>
              <div class="col-md-12">
                <input
                  type="text"
                  required
                  maxlength="100"
                  ref="barcode_input"
                  class="form-control form__input"
                  v-model="barcode.barcode"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-0 form-control-label form__label">
                <h5>Note</h5>
              </label>
              <div class="col-md-12">
                <input
                  type="text"
                  maxlength="200"
                  class="form-control form__input"
                  v-model="barcode.note"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-0 form-control-label form__label">
                <h5>Status</h5>
              </label>
              <div class="col-md-12">
                <select class="form-control w-200" v-model="barcode.status">
                  <option value="active">ACTIVE</option>
                  <option value="inactive">INACTIVE</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="addBarcode"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { illicitBarcodeService } from "@/api/client";
import { responseErrorMapping } from "./model/error";

export default {
  name: "CreateModal",

  data() {
    return {
      barcode: {
        barcode: "",
        note: "",
        status: "active",
      },
    };
  },

  methods: {
    async isFormValid() {
      const { barcode } = this.barcode;

      if (!barcode) {
        await this.$dialogs.alert("กรุณาระบุ barcode ให้ถูกต้อง");
        this.$refs.barcode_input.focus();
        return false;
      }

      if (barcode.length > 100) {
        await this.$dialogs.alert("ความยาว barcode ต้องไม่เกิน 100 charactors");
        this.$refs.barcode_input.focus();
        return false;
      }

      return true;
    },

    close() {
      this.$modal.hide("create-modal");
    },

    async addBarcode() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        const body = this.barcode;

        await illicitBarcodeService.createNewBarcode(body);
        await this.$notify("Create barcode Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control-label h5 {
  margin-bottom: 0px;
}
</style>
