export const columns = [
  {
    label: "Barcode",
    field: "barcode",
  },
  {
    label: "Status",
    field: "status",
    formatFn: function (value) {
      return value.toUpperCase();
    },
  },
  {
    label: "Date Added",
    field: "created_time",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Last Modified Date",
    field: "modified_time",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Last Modified By",
    field: "modified_by",
  },
];
